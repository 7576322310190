<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" title="调价" @closed="cancel" :close-on-click-modal="false">
    <el-tabs v-model="currentTab">
      <el-tab-pane label="批发价" name="basic">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="110px" v-if="form">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="goodsPrice" label="批发价">
                <price-input :min="0" size="small" v-model="form.goodsPrice " style="width: 250px;" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="_dateRange" label="开始日期">
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="form._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
          <div class="dialog-footer h c">
            <div class="flex"></div>
            <el-button type="text" @click="dialog=false">取消</el-button>
            <el-button :loading="saving" type="primary" @click="handleSave">保存</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="经销商类型" name="second">
        <el-form ref="form1" :model="form" :rules="rules1" label-position="right" label-width="110px" v-if="form1">
          <el-table v-loading="loading" :data="form1.distributorPrice" size="small" style="width: 100%;">
            <el-table-column label="经销商类型" prop="name" width="100" />
            <el-table-column label="是否调价" width="100">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.enable" />
              </template>
            </el-table-column>
            <el-table-column label="价格" width="200">
              <template slot-scope="scope">
                <price-input :min="0" :disabled="!scope.row.enable" v-model="scope.row.goodsPrice" size="small" />
              </template>
            </el-table-column>
            <el-table-column label="日期">
              <template slot-scope="scope">
                <el-date-picker type="daterange" :disabled="!scope.row.enable" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
              </template>
            </el-table-column>
          </el-table>
          <div class="dialog-footer h c">
            <div class="flex"></div>
              <el-button type="text" @click="dialog=false">取消</el-button>
              <el-button :loading="saving" type="primary" @click="handleSaveType">保存</el-button>
            </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="指定经销商" name="third">
        <el-form ref="form2" :model="form" :rules="rules2" label-position="right" label-width="110px" v-if="form2">
        <datagrid-picker reference="选择经销商" reference-type="info" url="api/distributor" :query-define="dis.queryDefine" :popper-width="800" @change="handleDisAdd">
          <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
            <el-input :maxlength="20" v-model="dis.queryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;">
              <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
            </el-input>
          </div>
          <el-table-column prop="name" label="经销商" min-width="160" />   
          <el-table-column label="经销商类型" width="160" :formatter="r => {return getName(r.level);}" />
          <el-table-column label="所在地区" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}"/>      
        </datagrid-picker>
        <el-table v-loading="loading" :data="form2.distributorPrice" size="small" style="width: 100%;">
            <el-table-column label="经销商" prop="name" />            
            <el-table-column label="价格" width="145">
              <template slot-scope="scope">
                <price-input :min="0"  v-model="scope.row.goodsPrice" size="small" />
              </template>
            </el-table-column>
            <el-table-column label="日期">
              <template slot-scope="scope">
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="40">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-delete" @click="remove(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        <div class="dialog-footer h c">
            <div class="flex"/>
            <el-button type="text" @click="dialog=false">取消</el-button>
            <el-button :loading="saving" type="primary" @click="handleSaveSingle">保存</el-button>
        </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <!--分页组件-->
  </el-dialog>
</template>
<script>
import { add } from "@/api/adjust";
import request from "@/utils/request";
export default {
  data() {
    return {
      currentTab: "basic",
      dialog: false,
      saving: false,
      loading: false,
      priceSaving: false,
      dis:{
        queryDefine:{}
      },
      form: {
        priceType: 0,
        goodsId: "",
        goodsPrice: null,
        beginTime: "",
        endTime: "",
        _dateRange: [],
      },
      disLevel:[],
      form1: {
        distributorPrice: []
      },
      form2:{
        distributorPrice: []
      },
      rules1:{},
      rules2:{},
      rules: {
        goodsPrice: [
          { required: "true", message: "批发价为必填项", trigger: "blur" }
        ],
        _dateRange: [
          {
            required: "true",
            message: "开始时间和结束时间必填项",
            trigger: "change"
          }
        ]
      },
      readForm: {
        distributor: []
      }
    };
  },
  created() {
    this.getDealerData();
  },
  methods: {
    remove(d){
      this.form2.distributorPrice.shift(d);
    },
    handleDisAdd(v,d,data){
      var exist = false;
      this.form2.distributorPrice.forEach(p=>{
        if(p.id===data.id){
           exist = true; 
        }
      })
      if(!exist){
        this.form2.distributorPrice.push(data);
      }
    },
    getName(val) {      
      let t = "";
      this.disLevel.forEach(i=>{
        if(i.id===val){
          t = i.name;
        }
      })
      return t;
    },
    cancel() {
      this.currentTab = "basic";
      this.form.priceType = 0;
      this.form.goodsPrice = null;
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form._dateRange = [];
      this.form1.distributorPrice = [];
    },
    handleSaveSingle(){
      let o = [];
      this.form2.distributorPrice.forEach(dp => {
        if ((dp.goodsPrice && dp.goodsPrice>0) && (dp._dateRange && dp._dateRange.length === 2)) {
          o.push({
            priceType: 2,
            goodsPrice: dp.goodsPrice,
            goodsId: this.form.goodsId,
            beginTime: dp._dateRange[0],
            endTime: dp._dateRange[0],
            distributorId: dp.id
          });
        }
      });
      if (o && o.length > 0) {
        this.saving = true;
        this.$confirm("确认调价, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.dialog = false;
            add(o)
              .then(res => {
                this.$message({
                  type: "success",
                  message: "调价申请成功!"
                });
                this.saving = false;
                this.$parent.init();
              })
              .catch(err => {
                this.saving = false;
                console.log(err.response.data.message);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消调价申请"
            });
          });
      }else{
        this.$message({type: "info",message: "请选择经销商并设置价格及日期"});
      }
    },
    handleSaveType() {
      let o = [];
      this.form1.distributorPrice.forEach(dp => {
        if (dp.enable && dp._dateRange && dp._dateRange.length === 2) {
          o.push({
            priceType: 1,
            goodsPrice: dp.goodsPrice,
            goodsId: this.form.goodsId,
            beginTime: dp._dateRange[0],
            endTime: dp._dateRange[0],
            distributorLeve: dp.id
          });
        }
      });
      if (o && o.length > 0) {
        this.saving = true;
        this.$confirm("确认调价, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.dialog = false;
            add(o)
              .then(res => {
                this.$message({
                  type: "success",
                  message: "调价申请成功!"
                });
                this.saving = false;
                this.$parent.init();
              })
              .catch(err => {
                this.saving = false;
                console.log(err.response.data.message);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消调价申请"
            });
          });
      }
    },
    handleSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let o = [];
          o = [
            {
              priceType: 0,
              goodsPrice: this.form.goodsPrice,
              goodsId: this.form.goodsId,
              beginTime: this.form._dateRange[0],
              endTime: this.form._dateRange[1]
            }
          ];
          this.$confirm("确认调价, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.dialog = false;
              // 发送请求
              add(o)
                .then(res => {
                  this.$message({
                    type: "success",
                    message: "调价申请成功!"
                  });
                  this.saving = false;
                  this.$parent.init();
                })
                .catch(err => {
                  this.saving = false;
                  console.log(err.response.data.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消调价申请"
              });
            });
        }
      });
    },
    // 获取经销商类型数据
    getDealerData(data, retailPrice) {
      request({
        url: "api/distributorLeve/list",
        method: "get",
        params: {
          page: 0,
          size: 1000
        }
      })
        .then(res => {
          if (res) {
            this.form.goodsId = data.goodsId;
            this.form.goodsPrice = retailPrice;
            this.form1.distributorPrice = this.disLevel = res;
          }
        })
        .catch(err => {});
    }
  }
};
</script>
